import type {ComponentProps} from 'react'
import {useMemo} from 'react'
import {useLayoutEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {IconButton, styled, useMediaQuery, useTheme, ClickAwayListener, Box} from '@mui/material'
import SubjectIcon from '@mui/icons-material/Subject'
import {ChatList, ChatView} from '@zel-labs/firebase-chat'
import ReactMarkdown from 'react-markdown'

import {Breadcrumbs, HomeBreadcrumb, PageContainer} from '@zel-labs/shared/mui'
import {CopilotBreadcrumb} from './CopilotBreadcrumb'
import {CoachingBreadcrumb, CoachingContextProvider, useCoaching, useCoachingContext} from '../coaching'
import {MeetingBreadcrumb, MeetingContextProvider, useMeeting, useMeetingContext} from '../meeting'
import {MeetingCopilotContextProvider} from './MeetingCopilotContext'


export function MeetingCopilotPage() {
  const {coachingId, meetingId} = useParams<{ coachingId: string, meetingId: string }>()
  const {coachingSnapshot, role} = useCoaching(coachingId)
  const {meetingSnapshot} = useMeeting(meetingId, coachingSnapshot)

  if (role == null) {
    return null
  }

  return <CoachingContextProvider coaching={coachingSnapshot} role={role}>
    <MeetingContextProvider meeting={meetingSnapshot}>
      <MeetingCopilotContextProvider>
        <MeetingCopilotLayout/>
      </MeetingCopilotContextProvider>
    </MeetingContextProvider>
  </CoachingContextProvider>
}

function MeetingCopilotLayout() {
  const {coaching, role} = useCoachingContext()
  const {data, meeting} = useMeetingContext()
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState<boolean>(!isNarrow)

  useLayoutEffect(() => {
      setOpen(!isNarrow)
    },
    [isNarrow]
  )

  const samplePrompts = useMemo(
    () => role === 'coachee' ? coacheePrompts : coachPrompts,
    [role]
  )

  if (coaching == null || data == null || meeting == null) {
    return null
  }

  return <PageContainer fullScreen={true} title={data?.title ?? data?.recordingFileName ?? 'MAX'}>
    <PageHeader>
      <SidebarContainer open={open} className="toolbar">
        <SidebarControls>
          <IconButton onClick={() => setOpen((open) => !open)}>
            <SubjectIcon/>
          </IconButton>
        </SidebarControls>
      </SidebarContainer>
      <Breadcrumbs separator="›">
        <HomeBreadcrumb/>
        <CoachingBreadcrumb/>
        <MeetingBreadcrumb/>
        <CopilotBreadcrumb/>
      </Breadcrumbs>
    </PageHeader>

    <ChatContainer>
      {open && isNarrow ? <ClickAwayListener onClickAway={() => setOpen(false)}>

        <SidebarContainer open={open}>
          <ChatList/>
        </SidebarContainer>
      </ClickAwayListener> : <SidebarContainer open={open}>
        <ChatList/>
      </SidebarContainer>
      }
      <ChatView mode={'chat'} samplePrompts={samplePrompts} ContentComponent={MessageMarkdownComponentWithImage}/>
    </ChatContainer>
  </PageContainer>
}

function MessageMarkdownComponentWithImage({children}: Pick<ComponentProps<typeof ReactMarkdown>, 'children'>) {
  return <Box sx={{px: 4}}><StyledMarkdown
  >{children}</StyledMarkdown></Box>
}

const StyledMarkdown = styled(ReactMarkdown)(({theme}) => ({
  ...theme.typography.body2,
  maxWidth: 'fit-content',
  overflowX: 'hidden',
  'img': {
    maxWidth: '100%',
    height: 'auto',
  }
}))


const SidebarContainer = styled(
  'div', {
    name: 'SidebarContainer', slot: 'Root',
    shouldForwardProp: (prop) => prop !== 'open'
  })<SidebarProps>(
  ({theme, open}) => ({
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    width: open ? theme.breakpoints.values.lg / 4 : 0,
    '&.toolbar': {
      width: open ? theme.breakpoints.values.lg / 4 : 'auto',
      backgroundColor: open
        ? theme.palette.background.paper
        : theme.palette.background.default,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    padding: 0, margin: 0,
    display: 'flex', flexDirection: 'column',
    flexShrink: 0, flexGrow: 0, justifyContent: 'stretch',
    '&:not(.toolbar)': {
      boxShadow: theme.shadows[2],
    },
    '& > *': {
      flexGrow: 1
    },
    [theme.breakpoints.down('md')]: {
      '&:not(.toolbar)': {
        position: 'absolute',
        left: 0, top: 0, bottom: 0,
        width: open ? '80vw' : 0,
        zIndex: 1000
      },
      '&.toolbar': {
        width: 'auto'
      }
    }
  })
)

const SidebarControls = styled(
  'div', {name: 'SidebarControls', slot: 'Root'}
)(
  ({theme}) => ({
    display: 'flex', flexDirection: 'row',
    justifyContent: 'flex-start', alignItems: 'center',
    padding: 0, margin: 0
  })
)

interface SidebarProps {
  open: boolean
}


const PageHeader = styled(
  'div', {name: 'PageHeader', slot: 'Root'}
)(
  ({theme}) => ({
    display: 'flex', flexDirection: 'row',
    justifyContent: 'stretch', alignItems: 'stretch',
    flexShrink: 0, flexGrow: 0, overflow: 'hidden'
  })
)

const ChatContainer = styled(
  'div', {name: 'ChatContainer', slot: 'Root'}
)(
  ({theme}) => ({
    display: 'flex', flexDirection: 'row',
    alignItems: 'stretch', justifyContent: 'stretch',
    flexGrow: 1, flexShrink: 1, overflow: 'hidden',
    position: 'relative'
  })
)

const coachPrompts = [
  'Foglald össze az interjút!',
  'Milyen következetlenségeket találsz az interjúban?',
  'Adj egy 3 mondatos összefoglalót, amit elrakhatok magamnak emlékeztetőnek!',
  'Mi a legmarkánsabban megjelenő vezetői kihívás az interjú alapján?',
  'Javasolj kérdéseket a következő alkalomra!',
  'Hogyan írnád le a kihívásokat, amelyekkel az ügyfélnek szembe kell néznie?',
  //'Milyen archetipikus mintázatokat fedezel fel Jung archetípus rendszere mentén a vezetőben?',
  'Hasonlítsd össze a korábbi interjúkkal: mik a visszatérő problémák, mik a mostanában nem említettek és milyen fejlődés látszik',
  'Kik voltak azok az emberek akiket a coachee említett az interjú során és milyen relevanciában említette őket?',
  'Melyek azok a kérdések, amelyeket az ügyfél mintha elkerülne a beszélgetés során? Milyen kérdésekkel tudnád nyomon követni a kitéréseket?',
 // 'Milyen archetipikus mintázatokat fedezel fel Jung archetípus rendszere mentén a coachban?',
  'Milyen kognitív hibákat ismersz fel a coachee szövegének elemzésében?',
  'Milyen további kérdések segítenék az ügyfelet abban, hogy feltárja helyzetét, és megoldást találjon rá?',
  'Mi maradt ki és fontos lehet utólag megkérdezni',
  'Mi az amit a vezető nem tud magáról a beszélgetés alapján?',
  'Mi volt a beszélgetés fénypontja?',
 // 'Készítsd el az interjúalany enneagramját',
  'Elemezd az interjút a THE STOIC modell alapján',
  'Látsz ellentmondást az interjúalany vallott és követett értékei közt?',
  'Berne játszmaelmélete mentén milyen tranzakciók vannak jellemzően a beszélgetők között? Milyen játszmákat azonosítasz és kik között?'
  // 'Keress az interjúkat az interjúzó ICF kompetenciáira utaló pozitív és negatív jeleket.',
  // 'Adj visszajelzést nekem (coachnak), az ICF coaching kompetenciák alapján az interjúról',
]

const coacheePrompts = [
  'Foglald össze az interjút',
  'A beszélgetés alapján milyen erősségeim vannak és miként aknázhatnám őket jobben ki a problémáim megoldásában?',
  'Készíts SWOT elemzést a helyzetemről',
  'Milyen konkrét feladatok, javaslatok gyűltek össze, amire a következő interjúig érdemes figyelnem?',
  'A beszélgetés alapján milyen fejlesztendő területeim vannak és ezekből milyen problémák származhatnak azokból a problémákból, amiket az interjúban említettem?',
  'Szedd össze a feladatlistámat a következő interjúig',
  'Miben fejlődtem a coaching folyamat során és miben nem?',
  'Készíts egy 3 mondatos mantrát, amit kinyomtathatok az asztalomra',
  'Hogyan lépnél tovább ebben a helyzetben?',
  'Hogy állunk a kitűzött célokkal - az összes eddigi interjú figyelembevételével?',
  'Keress az interneten olyan könyveket, videókat, cikkeket, amik segíthetnek nekem reflektálni a saját magam működésére.',
  'Mi lehet a legfontosabb, ami kiderült a megbeszélésből és miért?',
  'Mondj hasonló gyakorlati példákat a helyzetemhez és mutass jó megoldási gyakorlatokat',
  'Látsz ellentmondást a vallott és követett értékeim közt?',
  'Mi volt a beszélgetés fénypontja?',
  'Mi maradt ki, amit fontos lehet legközelebb megbeszélni?'
  // 'A beszámolóm alapján készítsd el a szervezet diagnózisát a 7S modell mentén',

]
