import {ProtectedRoute, useUserContext} from '@zel-labs/auth'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {CenteredContainer, PageContainer} from '@zel-labs/shared/mui'
import {Stack, Typography} from '@mui/material'
import {useFunctions} from './useFunctions'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Forbidden,
  NotFound,
  typeConverter,
  useFirebaseAnalytics,
  useFirestore,
  useFirestoreSnapshot
} from '@zel-labs/firebase-chat'
import {logEvent} from 'firebase/analytics'
import type {DocumentReference} from 'firebase/firestore'
import {doc} from 'firebase/firestore'
import type {Invitation} from '@zel-labs/shared/model'
import type {User} from 'firebase/auth'
import {Routing} from '@zel-labs/routing'
import {FirebaseError} from 'firebase/app'
import {LoadingButton} from '@mui/lab'
import * as Sentry from '@sentry/react'

export function AcceptInvitationPage() {
  const {t} = useTranslation()

  const {user, state} = useUserContext()

  const {invitationId} = useParams<{ invitationId: string }>()


  if (invitationId == null) {
    throw new NotFound()
  }

  const {analytics} = useFirebaseAnalytics()
  useEffect(() => {
    if (user != null) {
      logEvent(analytics, 'invitation_page_opened', {
        invitation_id: invitationId
      })
    }
  }, [analytics, invitationId, user])


  const invitationRef = useInvitationRef(invitationId)

  if (user === undefined) {
    return null
  }

  return <ProtectedRoute authMethod="signUp">
    <PageContainer title={t('title')} fullScreen={true}>
      <CenteredContainer>
        <AcceptInvitationForm invitationRef={invitationRef} user={user}/>
      </CenteredContainer>
    </PageContainer>
  </ProtectedRoute>
}

function AcceptInvitationForm({invitationRef, user}: {
  invitationRef: DocumentReference<Invitation>,
  user: User | null | undefined
}) {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {acceptInvitation} = useFunctions()

  const invitationSnapshot = useFirestoreSnapshot(invitationRef)
  const invitation: Invitation | undefined = useMemo(
    () => invitationSnapshot?.data(),
    [invitationSnapshot]
  )

  const [errorCode, setErrorCode] = useState<string>()
  const [acceptState, setAcceptState] = useState<'idle' | 'working'>('idle')

  const {analytics} = useFirebaseAnalytics()
  const accept = useCallback(
    async () => {
      try {
        setAcceptState('working')
        setErrorCode(undefined)
        await acceptInvitation(invitationRef)
        logEvent(analytics, 'invitation_accepted', {
          invitation_id: invitationRef.id
        })
        navigate(Routing.home())
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.message === 'invitation/already-accepted') {
            navigate(Routing.home())
          } else {
            setErrorCode(error.message)
          }
        } else {
          setErrorCode('invitation/unknown')
        }
      } finally {
        setAcceptState('idle')
      }
    },
    [acceptInvitation, analytics, invitationRef, navigate]
  )

  if (invitation === undefined) {
    return null
  }
  if (user == null) {
    throw new Forbidden()
  }

  if (invitation === null) {
    throw new NotFound()
  }


  if (invitation.recipient.email != null && user.email?.toLowerCase() !== invitation.recipient.email.toLowerCase()) {
    Sentry.captureException(new Forbidden(), {
      user: {id: user?.uid}
    })
    throw new Forbidden()

  }


  return <Stack direction="column" justifyContent="center" alignItems="center" flexGrow={1}>
    <Typography variant="h3" component="h1" gutterBottom={true}><Trans
      i18nKey="invitation.accept.title"/></Typography>
    <Typography variant="body1" gutterBottom={true}><Trans
      i18nKey="invitation.accept.description"/></Typography>
    <LoadingButton loading={acceptState === 'working'} variant="contained" onClick={accept}><Trans
      i18nKey="invitation.accept.button"/></LoadingButton>
    {errorCode &&
      <Typography variant="body1" color="error">{t(`invitation.accept.errors.${errorCode}`)}</Typography>}
  </Stack>
}

function useInvitationRef(invitationId: string) {
  const firestore = useFirestore()
  return useMemo(
    () => doc(firestore, 'invitations', invitationId).withConverter(typeConverter<Invitation>()),
    [firestore, invitationId]
  )
}
