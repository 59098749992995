import {
  Stack,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  IconButton,
  Link
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import type { FormEvent } from 'react'
import { useCallback, useState } from 'react'
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  type UserCredential
} from 'firebase/auth'
import { LoadingButton } from '@mui/lab'
import { useAuthenticationContext } from '..'
import { NoWrapButton, NoWrapFormHelperText } from './AuthDialog'
import { useFirebaseAuth } from '@zel-labs/firebase-chat'
import { FirebaseError } from 'firebase/app'
import { errorMessage } from './util'
import GoogleIcon from '@mui/icons-material/Google'
import {Routing} from '@zel-labs/routing'

export function SignInForm() {
  const { t } = useTranslation()
  const { auth } = useFirebaseAuth()
  const { hideAuthentication, signUp, resetPassword } = useAuthenticationContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)


  const [state, setState] = useState<'idle' | 'pending' | 'success' | 'failed'>('idle')

  const resetFields = useCallback(() => {
    setEmail('')
    setPassword('')
  }, [])

  const resetErrors = useCallback(() => {
    setEmailError(null)
    setPasswordError(null)
    setError(null)
  }, [])

  const handleSuccess = useCallback(
    async (credential: UserCredential) => {
      setState('success')
      resetErrors()
      resetFields()
      hideAuthentication()
      setState('idle')
    }, [hideAuthentication, resetErrors, resetFields]
  )


  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!['idle', 'failed'].includes(state)) {
        throw new Error(`Cannot submit while in state ${state}`)
      }

      setState('pending')
      setError(null)
      setEmailError(null)
      setPasswordError(null)
      try {
        const credential = await signInWithEmailAndPassword(auth, email, password)
        await handleSuccess(credential)
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code==='auth/invalid-email') {
            setEmailError('Invalid email')
          } else if (e.code==='auth/invalid-credential') {
            setError('Invalid email or password')
          } else if (e.code==='auth/missing-password') {
            setPasswordError('Missing password')
          } else {
            setError(errorMessage(e))
          }
        } else {
          setError(errorMessage(e))
        }
        setState('failed')
      }
    },
    [auth, email, handleSuccess, password, state]
  )

  const signInWithGoogle = useCallback(
    async () => {
      const provider = new GoogleAuthProvider()
      try {
        setState('pending')
        const credential = await signInWithPopup(auth, provider)
        await handleSuccess(credential)
      } catch (e) {
        setError(errorMessage(e))
        setState('failed')
      }
    }, [auth, handleSuccess])

  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:signIn.form.title" /></DialogTitle>

    <DialogContent>
      <Stack gap={1} flexGrow={1}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
          <FormHelperText sx={{ whiteSpace: 'nowrap' }}><Trans
            i18nKey="auth:signIn.form.signInWithOther" /></FormHelperText>
          <Stack direction="row">
            <IconButton onClick={signInWithGoogle}><GoogleIcon /></IconButton>
          </Stack>
          <FormHelperText sx={{ lineHeight: 1.2 }}><Trans i18nKey="auth:signIn.form.consent">
            <Link href={Routing.termsOfUse()} target="_blank" rel="noopener noreferrer" />
            <Link href={Routing.privacyStatement()} target="_blank" rel="noopener noreferrer" />
          </Trans></FormHelperText>
        </Stack>


        <FormHelperText><Trans i18nKey="auth:signIn.form.useCredentials" /></FormHelperText>
        <TextField
          size="small" fullWidth={true}
          label={t('auth:signIn.form.email')} value={email}
          error={emailError!=null} helperText={emailError}
          onChange={e => setEmail(e.target.value)} />

        <TextField
          size="small" fullWidth={true} type="password"
          error={passwordError!=null} helperText={passwordError}
          label={t('auth:signIn.form.password')} value={password}
          onChange={e => setPassword(e.target.value)} />

        {error!=null && <FormHelperText error={true}>{error}</FormHelperText>}

        <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
          <NoWrapFormHelperText><Trans i18nKey="auth:signIn.form.forgot" /></NoWrapFormHelperText>
          <NoWrapButton variant="text" sx={{ textTransform: 'inherit' }} onClick={resetPassword} size="small"><Trans
            i18nKey="auth:signIn.form.reset" /></NoWrapButton>
        </Stack>


      </Stack>
    </DialogContent>
    <DialogActions>
      <Stack direction="row" justifyContent="flex-start" alignItems="baseline" flexWrap="wrap">
        <NoWrapFormHelperText><Trans i18nKey="auth:signIn.form.newHere" /></NoWrapFormHelperText>
        <NoWrapButton variant="text" sx={{ textTransform: 'inherit' }} onClick={signUp} size="small"><Trans
          i18nKey="auth:signIn.form.signUp" /></NoWrapButton>
      </Stack>
      <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                     loading={state==='pending'} autoFocus={true}>
        <Trans i18nKey="auth:signIn.form.submit" />
      </LoadingButton>
    </DialogActions>
  </form>
}

